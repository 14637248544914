<script lang="ts">
	import cn from 'classnames';
	export let tag: 'h1' | 'h2' | 'h3' | 'h4' | 'strong';
	export let size: 'lg' | 'md' | 'sm' | 'xs' | undefined = undefined;
</script>

<svelte:element
	this={tag}
	class={cn(
		'block font-bold',
		size === 'md' ? 'text-xl md:text-3xl' : size === 'sm' ? 'text-xl md:text-2xl' : '',
		$$restProps.class
	)}
>
	<slot />
</svelte:element>
